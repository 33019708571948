<template>
  <private-card
    title="Experiencia"
    :urlTitleIcon="urlTitleIcon"
    :editIcon="editable"
    editIconType="create-card-info-image"
    eventName="onClickUpdateJob"
    @onClickUpdateJob="onClickUpdateJob"
  >
    <template v-slot:private-card-body>
      <JobRow
        v-for="(job, index) in userJobs"
        :key="job.id"
        :job="job"
        :separator="index !== userJobs.length - 1"
        :editable="editable"
        @removeJob="onRemoveJob"
        @updateJob="onUpdateJob"
      ></JobRow>
    </template>

    <template v-slot:private-card-modal>
      <modal-component
        id="create-job-modal"
        title="Experiencia"
        size="lg"
        ok-title="Guardar cambios"
        :hide-footer="true"
      >
        <JobForm
          v-model="createJob"
          @confirmed="onConfirmedCreateJob"
        />
      </modal-component>
    </template>

  </private-card>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import PrivateCard from '@/components/Card/PrivateCard'
import JobForm from './JobForm.vue'
import JobRow from './JobRow.vue'

export default {
  name: 'JobsCard',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    userJobs: {
      type: Array,
      required: true
    },
    urlTitleIcon: {
      type: String
    }
  },
  components: { ModalComponent, PrivateCard, JobForm, JobRow },
  data () {
    return {
      createJob: this.getEmpty()
    }
  },
  methods: {
    getEmpty () {
      return {
        name: '',
        description: '',
        year: '',
        roleIds: [],
        jobCategoryId: '',
        link: null,
        images: [],
        newImages: [],
        deletedImages: []
      }
    },
    encodeImage (image) {
      return new Promise((resolve) => {
        var reader = new FileReader()
        reader.onloadend = function (e) {
          resolve({
            extension: image.name.split('.').pop(),
            image: e.target.result.split(',').pop()
          })
        }
        reader.readAsDataURL(image.file)
      })
    },
    onConfirmedCreateJob (createJob) {
      this.lockScreen()

      Promise.all(createJob.newImages.map((image) => this.encodeImage(image)))
        .then(i => {
          createJob.newImages = i
          delete createJob.images
          this.$store.dispatch('createJob', createJob)
            .then((newJob) => {
              this.$bvModal.hide('create-job-modal')
              this.$store.dispatch('showConfirmationModal')
              const user = this.$store.getters.loggedUser.getTechnician
              const jobs = user.getJobs
              jobs.push(newJob)
              user.updateJobs(jobs)
              this.createJob = this.getEmpty()
              this.unlockScreen()
            })
        })

      this.unlockScreen()
    },
    onUpdateJob (originalJob) {
      const job = { ...originalJob }
      this.lockScreen()

      Promise.all(job.newImages.map((image) => this.encodeImage(image)))
        .then(i => {
          job.newImages = i
          delete job.images
          this.$store.dispatch('updateJob', job)
            .then((updatedJob) => {
              this.$store.dispatch('getUser')
                .then(() => {
                  this.unlockScreen()
                  this.$store.dispatch('showConfirmationModal')
                  /*
                  const user = this.$store.getters.loggedUser.getTechnician
                  const jobs = user.getJobs.filter(j => j.getId !== updatedJob.getId)
                  jobs.push(updatedJob)
                  user.updateJobs(jobs)
                  */
                  this.$bvModal.hide(updatedJob.getId)
                  this.createJob = this.getEmpty()
                })
            })
        })
    },
    onClickUpdateJob () {
      this.$bvModal.show('create-job-modal')
    },
    onRemoveJob (id) {
      this.lockScreen()
      this.$store.dispatch('deleteJob', id)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          const user = this.$store.getters.loggedUser.getTechnician
          const jobs = user.getJobs.filter(currentJob => id !== currentJob.getId)
          user.updateJobs(jobs)
        })
    }
  }
}
</script>
