<template>
  <private-card
    title="Lista de equipamientos"
    :urlTitleIcon="urlTitleIcon"
    :editIcon="editable"
    editIconType="create-card-info-image"
    eventName="onClickUpdateHardware"
    @onClickUpdateHardware="onClickUpdateHardware"
  >  >
    <template v-slot:private-card-body>
      <HardwareRow
        v-for="(hardware, index) in userHardware"
        :key="hardware.id"
        :hardware="hardware"
        :separator="index !== userHardware.length - 1"
        :editable="editable"
        @removeHardware="onRemoveHardware"
        @updateHardware="onUpdateHardware"
      ></HardwareRow>
    </template>

    <template v-slot:private-card-modal>
      <modal-component
        id="create-hardware-modal"
        title="Lista de equipamientos"
        size="lg"
        ok-title="Guardar cambios"
        :hide-footer="true"
      >
        <HardwareForm
          v-model="createHardware"
          @confirmed="onConfirmedCreateHardware"
        />
      </modal-component>
    </template>

  </private-card>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import PrivateCard from '@/components/Card/PrivateCard'
import HardwareForm from './HardwareForm.vue'
import HardwareRow from './HardwareRow.vue'

export default {
  name: 'HardwareCard',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    userHardware: {
      type: Array,
      required: true
    },
    urlTitleIcon: {
      type: String
    }
  },
  components: { ModalComponent, PrivateCard, HardwareForm, HardwareRow },
  data () {
    return {
      createHardware: this.getEmpty()
    }
  },
  methods: {
    getEmpty () {
      return {
        name: '',
        brandId: null,
        productTypeId: null,
        description: null,
        visible: false
      }
    },
    onConfirmedCreateHardware (createHardware) {
      this.lockScreen()
      this.$store.dispatch('createHardware', createHardware)
        .then((newHardware) => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          const user = this.$store.getters.loggedUser.getTechnician
          const hardware = user.getHardware
          hardware.push(newHardware)
          user.updateHardware(hardware)
          this.$bvModal.hide('create-hardware-modal')
          this.createHardware = this.getEmpty()
        })
    },
    onUpdateHardware (hardware) {
      this.lockScreen()
      this.$store.dispatch('updateHardware', hardware)
        .then((updatedHardware) => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          const user = this.$store.getters.loggedUser.getTechnician
          const hardware = user.getHardware.filter(h => h.getId !== updatedHardware.getId)
          hardware.push(updatedHardware)
          user.updateHardware(hardware)
          this.$bvModal.hide(updatedHardware.getId)
          this.createHardware = this.getEmpty()
        })
    },
    onClickUpdateHardware () {
      this.$bvModal.show('create-hardware-modal')
    },
    onRemoveHardware (id) {
      this.lockScreen()
      this.$store.dispatch('deleteHardware', id)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          const user = this.$store.getters.loggedUser.getTechnician
          const hardware = user.getHardware.filter(currentHardware => id !== currentHardware.getId)
          user.updateHardware(hardware)
        })
    }
  }
}
</script>
