<template>
  <div class="professionalSkill-row">
    <div class="professionalSkill-row-info">
      <h6 class="font-weight-bold">{{ title }}</h6>
      <div class="professionalSkill-row-list">
        <slot name="professional-skill-list"></slot>
      </div>
    </div>
    <hr v-if="separator">
  </div>
</template>

<script>
export default {
  name: 'ProfessionalSkillRow',
  props: {
    title: {
      type: String,
      required: true
    },
    separator: {
      type: Boolean,
      required: true
    }
  }
}
</script>
