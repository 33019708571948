<template>
  <b-row class="no-gutters d-block">
    <div class="d-flex justify-content-between align-items-center">
      <div class="language-row-text">
        <div class="language-row-title text-l">{{ language.language.name }}</div>
        <div class="language-row-level mt-1">{{ language.level.name }}</div>
      </div>
      <div class="language-row-icon">
        <img src="@/components/LanguageRow/edit_card.svg" style="cursor: pointer" @click="$emit('onClickEditLanguageRow', language.id)" v-if="editable">
      </div>
    </div>
    <hr v-if="separator">
    <modal-component
      :id="language.id"
      :deleteButton="true"
      title="Editar idioma"
      size="lg"
      ok-title="Guardar cambios"
      @confirmed="onConfirmedEditLanguage"
      @deleted="onDeleteLanguage"
    >
      <validation-observer ref="editLanguageForm" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
          <b-row>
            <b-col>
              <validation-provider name="idioma" rules="required" v-slot="validationContext">
                <b-form-group id="language-name" label-for="input-username">
                  <b-form-select
                    id="input-language-name"
                    name="input-language-name"
                    :state="getValidationState(validationContext)"
                    placeholder="Idioma"
                    v-model="editableLanguage.languageId"
                    :options="languages"
                  ></b-form-select>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider name="nivel" rules="required" v-slot="validationContext">
                <b-form-group id="language-level" label-for="input-language-level">
                  <b-form-select
                    id="input-language-level"
                    name="input-language-level"
                    :state="getValidationState(validationContext)"
                    placeholder="Nivel"
                    v-model="editableLanguage.levelId"
                    :options="levels"
                  ></b-form-select>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="primary" @click="onDeleteLanguage" class="d-flex d-sm-none">
                <b-icon-trash></b-icon-trash>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </modal-component>
    <remove-confirmation-modal
      :id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmRemoveLanguage"
      @onCancelRemoveConfirmation="onCancelRemoveLanguage"
    ></remove-confirmation-modal>
  </b-row>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import RemoveConfirmationModal from '../Modal/RemoveConfirmationModal'
import { BIconTrash } from 'bootstrap-vue'

export default {
  name: 'LanguageRow',
  components: {
    ModalComponent,
    RemoveConfirmationModal,
    BIconTrash
  },
  data () {
    return {
      editableLanguage: {
        id: this.language.id,
        languageId: this.language.language.id,
        levelId: this.language.level.id,
        certified: false
      }
    }
  },
  computed: {
    languages () {
      return this.$store.getters.languages
    },
    levels () {
      return this.$store.getters.levels
    },
    confirmationModalId () {
      return 'remove-confirmation' + this.editableLanguage.id
    }
  },
  props: {
    language: {
      type: Object,
      required: true
    },
    separator: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async onConfirmedEditLanguage (modalId) {
      const valid = await this.$refs.editLanguageForm.validate()
      if (!valid) {
        return
      }

      this.$store.dispatch('updateLanguage', this.editableLanguage)
        .then(() => {
          this.$bvModal.hide(modalId)
          this.$store.dispatch('showConfirmationModal')
        })
    },
    onDeleteLanguage () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onConfirmRemoveLanguage () {
      this.$emit('removeLanguage', this.editableLanguage.id)
    },
    onCancelRemoveLanguage () {
      this.$bvModal.hide(this.confirmationModalId)
    }
  }
}
</script>
