<template>
  <private-card
    title="Idiomas"
    :urlTitleIcon="urlTitleIcon"
    :editIcon="editable"
    editIconType="create-card-info-image"
    eventName="onClickEditLanguages"
    @onClickEditLanguages="onClickEditLanguages"
  >
    <template v-slot:private-card-body>
      <language-row
        v-for="(language, index) in userLanguages"
        :key="language.id"
        :language="language"
        :separator="index !== userLanguages.length - 1"
        :editable="editable"
        @onClickEditLanguageRow="onClickEditLanguageRow"
        @removeLanguage="removeLanguage"
      >
      </language-row>
    </template>

    <template v-slot:private-card-modal>
      <modal-component
        id="create-language-modal"
        title="Crear idioma"
        size="lg"
        ok-title="Guardar cambios"
        @confirmed="onConfirmedCreateLanguage"
      >
        <validation-observer ref="editLanguageForm" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
            <b-row>
              <b-col>
                <validation-provider name="idioma" rules="required" v-slot="validationContext">
                  <b-form-group id="language-name" label-for="input-username">
                    <b-form-select
                      id="input-language-name"
                      name="input-language-name"
                      :state="getValidationState(validationContext)"
                      placeholder="Idioma"
                      v-model="language.languageId"
                      :options="languages"
                      selected: null
                    ></b-form-select>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider name="nivel" rules="required" v-slot="validationContext">
                  <b-form-group id="language-level" label-for="input-language-level">
                    <b-form-select
                      id="input-language-level"
                      name="input-language-level"
                      :state="getValidationState(validationContext)"
                      placeholder="Nivel"
                      v-model="language.levelId"
                      :options="levels"
                      selected: null
                    ></b-form-select>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </modal-component>
    </template>
  </private-card>
</template>

<script>
import PrivateCard from '@/components/Card/PrivateCard'
import ModalComponent from '@/components/Modal/ModalComponent'
import LanguageRow from '@/components/LanguageRow/LanguageRow'

export default {
  name: 'LanguagesCard',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    userLanguages: {
      type: Array,
      required: true
    },
    urlTitleIcon: {
      type: String
    }
  },
  components: {
    ModalComponent,
    PrivateCard,
    LanguageRow
  },
  data () {
    return {
      language: {
        languageId: null,
        levelId: null,
        certified: false
      }
    }
  },
  computed: {
    languages () {
      return this.$store.getters.languages
    },
    levels () {
      return this.$store.getters.levels
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      if (this.editable) {
        this.$store.dispatch('getLanguages')
        this.$store.dispatch('getLevels')
      }
    },
    onClickEditLanguages () {
      this.$bvModal.show('create-language-modal')
    },
    async onConfirmedCreateLanguage (modalId) {
      const valid = await this.$refs.editLanguageForm.validate()
      if (!valid) {
        return
      }
      this.lockScreen()
      this.$store.dispatch('createLanguage', this.language)
        .then(() => {
          this.$bvModal.hide(modalId)
          this.resetForm()
          this.$store.dispatch('showConfirmationModal')
        })
    },
    resetForm () {
      this.language = {
        languageId: null,
        levelId: null,
        certified: false
      }
    },
    onClickEditLanguageRow (languageId) {
      this.$bvModal.show(languageId)
    },
    removeLanguage (languageId) {
      this.lockScreen()
      this.$store.dispatch('deleteLanguage', languageId)
        .then(() => {
          this.$bvModal.hide('remove-confirmation' + languageId)
          this.$bvModal.hide(languageId)
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>

<style scoped>

</style>
