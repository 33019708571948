<template>
  <b-row class="no-gutters d-block">
    <div class="d-flex justify-content-between align-items-center">
      <div class="language-row-text mr-3">
        <div class="language-row-title text-l">{{ certification.getName }}</div>
        <div class="language-row-level text-sm mt-2">{{ certification.getCenterName }}</div>
        <div class="language-row-level mt-2">{{ certification.getDatesInformation }}</div>
        <div class="language-row-level">{{ certification.getExpirationDatesInformation }}</div>
        <div class="language-row-observations text-xs mt-2" v-if="certification.getObservations !== 'NULL'">{{ certification.getObservations }}</div>
      </div>
      <div class="language-row-icon">
        <img src="@/components/CertificationRow/edit_card.svg" style="cursor: pointer" @click="$emit('onClickEditCertificationRow', certification.id)" v-if="editable">
      </div>
    </div>
    <hr v-if="separator">
    <modal-component
      :id="certification.getId"
      :deleteButton="true"
      title="Editar licencias y certificaciones"
      size="lg"
      ok-title="Guardar cambios"
      @confirmed="onConfirmedEditCertifications"
      @deleted="onDeleteCertifications"
    >
      <validation-observer ref="editCertificationsForm" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
          <b-row>
            <b-col>
              <validation-provider name="nombre" rules="required" v-slot="validationContext">
                <b-form-group id="certification-name" label-for="input-certification-name">
                  <b-form-input
                    id="input-certification-name"
                    name="input-certification-name"
                    :state="getValidationState(validationContext)"
                    placeholder="Título de la licencia"
                    v-model="editableCertification.name"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider name="título" rules="required" v-slot="validationContext">
                <b-form-group id="certification-center-name" label-for="input-certification-center-name">
                  <b-form-input
                    id="input-certification-center-name"
                    name="input-certification-center-name"
                    :state="getValidationState(validationContext)"
                    placeholder="Centro o escuela"
                    v-model="editableCertification.centerName"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group id="certification-observations" label-for="input-certification-observations">
                <b-form-textarea
                  id="input-certification-observations"
                  name="input-certification-observations"
                  v-model="editableCertification.observations"
                  placeholder="Descripción de la licencia"
                  rows="5"
                  max-rows="8"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="12 mb-2">Fecha de inicio</b-col>
                <b-col cols="6">
                  <b-form-group
                    id="certification-start-month"
                    label-for="input-certification-start-month"
                  >
                    <select-month
                      id="input-certification-start-month"
                      name="input-certification-start-month"
                      v-model="editableCertification.startAtMonth"
                      :selectedMonth="editableCertification.startAtMonth"
                      event-name="onChangeStartMonth"
                      @onChangeStartMonth="onChangeStartMonth"
                    ></select-month>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group id="certification-start-year" label-for="input-certification-start-year">
                    <select-year
                      id="input-certification-start-year"
                      name="input-certification-start-year"
                      v-model="editableCertification.startAtYear"
                      :selectedYear="editableCertification.startAtYear"
                      event-name="onChangeStartYear"
                      @onChangeStartYear="onChangeStartYear"
                      :maxYear="new Date().getFullYear()"
                    ></select-year>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="12 mb-2">Fecha de expedición</b-col>
                <b-col cols="6">
                  <b-form-group
                    id="certification-finish-month"
                    label-for="input-certification-finish-month"
                  >
                    <select-month
                      id="input-certification-finish-month"
                      name="input-certification-finish-month"
                      v-model="editableCertification.finishedAtMonth"
                      :selectedMonth="editableCertification.finishedAtMonth"
                      event-name="onChangeFinishMonth"
                      @onChangeFinishMonth="onChangeFinishMonth"
                    ></select-month>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    id="certification-finish-year"
                    label-for="input-certification-finish-year"
                  >
                    <select-year
                      id="input-certification-finish-year"
                      name="input-certification-finish-year"
                      v-model="editableCertification.finishedAtYear"
                      :selectedYear="editableCertification.finishedAtYear"
                      event-name="onChangeFinishYear"
                      @onChangeFinishYear="onChangeFinishYear"
                      :maxYear="new Date().getFullYear() + 5"
                    ></select-year>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-row>
                <b-col cols="12 mb-2">Fecha de caducidad</b-col>
                <b-col cols="6">
                  <b-form-group
                    id="certification-expiration-month"
                    label-for="input-certification-expiration-month"
                  >
                    <select-month
                      id="input-certification-expiration-month"
                      name="input-certification-expiration-month"
                      event-name="onChangeExpirationMonth"
                      @onChangeExpirationMonth="onChangeExpirationMonth"
                      v-model="editableCertification.expirationMonth"
                      :selectedMonth="editableCertification.expirationMonth"
                    ></select-month>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <validation-provider name="año" rules="numeric|between:1950,2100|length:4" v-slot="validationContext">
                    <b-form-group
                      id="certification-expiration-year"
                      label-for="input-certification-expiration-year"
                    >
                      <select-year
                        id="input-certification-expiration-year"
                        name="input-certification-expiration-year"
                        v-model="editableCertification.expirationYear"
                        :selectedYear="editableCertification.expirationYear"
                        event-name="onChangeExpirationYear"
                        @onChangeExpirationYear="onChangeExpirationYear"
                        :maxYear="new Date().getFullYear() + 20"
                      ></select-year>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="primary" @click="onDeleteCertifications" class="d-flex d-sm-none">
                <b-icon-trash></b-icon-trash>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </modal-component>
    <remove-confirmation-modal
      :id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmRemoveCertification"
      @onCancelRemoveConfirmation="onCancelRemoveCertification"
    ></remove-confirmation-modal>
  </b-row>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import SelectMonth from '../Form/SelectMonth'
import RemoveConfirmationModal from '../Modal/RemoveConfirmationModal'
import SelectYear from '../Form/SelectYear'
import { BIconTrash } from 'bootstrap-vue'

export default {
  name: 'CertificationRow',
  components: {
    SelectYear,
    RemoveConfirmationModal,
    SelectMonth,
    ModalComponent,
    BIconTrash
  },
  props: {
    certification: {
      type: Object,
      required: true
    },
    separator: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editableCertification: Object.assign({}, this.certification)
    }
  },
  computed: {
    confirmationModalId () {
      return 'remove-confirmation' + this.editableCertification.id
    }
  },
  methods: {
    async onChangeStartMonth (month) {
      this.editableCertification.startAtMonth = month
    },
    async onChangeStartYear (year) {
      this.editableCertification.startAtYear = year
    },
    onChangeFinishMonth (month) {
      this.editableCertification.finishedAtMonth = month
    },
    async onChangeFinishYear (year) {
      this.editableCertification.finishedAtYear = year
    },
    async onChangeExpirationMonth (month) {
      this.editableCertification.expirationMonth = month
    },
    async onChangeExpirationYear (year) {
      this.editableCertification.expirationYear = year
    },
    async onConfirmedEditCertifications (modalId) {
      const valid = await this.$refs.editCertificationsForm.validate()
      if (!valid) {
        return
      }
      this.lockScreen()
      this.$store.dispatch('updateCertification', this.editableCertification)
        .then(() => {
          this.$bvModal.hide(modalId)
          this.$store.dispatch('showConfirmationModal')
        })
    },
    onDeleteCertifications () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onConfirmRemoveCertification () {
      this.$emit('removeCertification', this.certification.getId)
    },
    onCancelRemoveCertification () {
      this.$bvModal.hide(this.confirmationModalId)
    }
  }
}
</script>
