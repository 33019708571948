<template>
  <b-card class="private-card">
    <b-card-title class="mb-4">
      <div class="d-flex align-items-center">
        <img :src="require('@/assets/icons/' + urlTitleIcon)" alt="card-icon" class="mr-2" v-if="urlTitleIcon"/>
        <span class="w-100">{{ title }}</span>
      </div>
      <span v-if="editIcon" :class="editIconType" @click="$emit(eventName)"></span>
    </b-card-title>
    <b-card-body>
      <slot name="private-card-body"></slot>
      <slot name="private-card-modal"></slot>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'PrivateCard',
  props: {
    title: String,
    urlTitleIcon: {
      type: String
    },
    eventName: String,
    editIcon: {
      type: Boolean,
      default: true
    },
    editIconType: {
      type: String,
      default: 'edit-card-info-image'
    }
  }
}
</script>

<style lang="less">
  @import 'privateCard.less';
</style>
