<template>
  <private-card
    title="Licencias y certificaciones"
    :urlTitleIcon="urlTitleIcon"
    :editIcon="editable"
    editIconType="create-card-info-image"
    eventName="onClickEditLicensesAndCertifications"
    @onClickEditLicensesAndCertifications="onClickEditLicensesAndCertifications"
  >
    <template v-slot:private-card-body>
      <certification-row
        v-for="(certification, index) in userCertifications"
        :key="certification.id"
        :certification="certification"
        :separator="index !== userCertifications.length - 1"
        :editable="editable"
        @onClickEditCertificationRow="onClickEditCertificationRow"
        @removeCertification="removeCertification"
      ></certification-row>
    </template>

    <template v-slot:private-card-modal>
      <modal-component
        id="create-licenses-certifications-modal"
        title="Crear licencias y certificaciones"
        size="lg"
        ok-title="Guardar cambios"
        @confirmed="onConfirmedCreateCertifications"
      >
        <validation-observer ref="createCertificationsForm" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
            <b-row>
              <b-col>
                <validation-provider name="nombre" rules="required" v-slot="validationContext">
                  <b-form-group id="certification-center-name" label-for="input-certification-center-name">
                    <b-form-input
                      id="input-certification-center-name"
                      name="input-certification-center-name"
                      :state="getValidationState(validationContext)"
                      placeholder="Centro o escuela"
                      v-model="createCertification.centerName"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider name="título" rules="required" v-slot="validationContext">
                  <b-form-group id="certification-name" label-for="input-certification-name">
                    <b-form-input
                      id="input-certification-name"
                      name="input-certification-name"
                      :state="getValidationState(validationContext)"
                      placeholder="Título de la licencia"
                      v-model="createCertification.name"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                  <b-form-group id="certification-observations" label-for="input-certification-observations">
                    <b-form-textarea
                      id="input-certification-observations"
                      name="input-certification-observations"
                      v-model="createCertification.observations"
                      placeholder="Descripción de la licencia"
                      rows="5"
                      max-rows="8"
                    ></b-form-textarea>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12 mb-2">Fecha de inicio</b-col>
                  <b-col cols="6">
                    <b-form-group
                      id="certification-start-month"
                      label-for="input-certification-start-month"
                    >
                      <select-month
                        id="input-certification-start-month"
                        name="input-certification-start-month"
                        v-model="createCertification.startAtMonth"
                        :selectedMonth="createCertification.startAtMonth"
                        event-name="onChangeStartMonth"
                        @onChangeStartMonth="onChangeStartMonth"
                      ></select-month>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group id="certification-start-year" label-for="input-certification-start-year">
                      <select-year
                        id="input-certification-start-year"
                        name="input-certification-start-year"
                        v-model="createCertification.startAtYear"
                        :selectedYear="createCertification.startAtYear"
                        event-name="onChangeStartYear"
                        @onChangeStartYear="onChangeStartYear"
                        :maxYear="new Date().getFullYear()"
                      ></select-year>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12 mb-2">Fecha de expedición</b-col>
                  <b-col cols="6">
                    <b-form-group
                      id="certification-finish-month"
                      label-for="input-certification-finish-month"
                    >
                      <select-month
                        id="input-certification-finish-month"
                        name="input-certification-finish-month"
                        v-model="createCertification.finishedAtMonth"
                        :selectedMonth="createCertification.finishedAtMonth"
                        event-name="onChangeFinishMonth"
                        @onChangeFinishMonth="onChangeFinishMonth"
                      ></select-month>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      id="certification-finish-year"
                      label-for="input-certification-finish-year"
                    >
                      <select-year
                        id="input-certification-finish-year"
                        name="input-certification-finish-year"
                        v-model="createCertification.finishedAtYear"
                        :selectedYear="createCertification.finishedAtYear"
                        event-name="onChangeFinishYear"
                        @onChangeFinishYear="onChangeFinishYear"
                        :maxYear="new Date().getFullYear() + 5"
                      ></select-year>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12 mb-2">Fecha de caducidad</b-col>
                  <b-col cols="6">
                    <b-form-group
                      id="certification-expiration-month"
                      label-for="input-certification-expiration-month"
                    >
                      <select-month
                        id="input-certification-expiration-month"
                        name="input-certification-expiration-month"
                        event-name="onChangeExpirationMonth"
                        @onChangeExpirationMonth="onChangeExpirationMonth"
                        v-model="createCertification.expirationMonth"
                        :selectedMonth="createCertification.expirationMonth"
                      ></select-month>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      id="certification-expiration-year"
                      label-for="input-certification-expiration-year"
                    >
                      <select-year
                        id="input-certification-expiration-year"
                        name="input-certification-expiration-year"
                        v-model="createCertification.expirationYear"
                        :selectedYear="createCertification.expirationYear"
                        event-name="onChangeExpirationYear"
                        @onChangeExpirationYear="onChangeExpirationYear"
                        :maxYear="new Date().getFullYear() + 20"
                      ></select-year>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </modal-component>
    </template>

  </private-card>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import PrivateCard from '@/components/Card/PrivateCard'
import CertificationRow from '@/components/CertificationRow/CertificationRow'
import SelectMonth from '../Form/SelectMonth'
import SelectYear from '../Form/SelectYear'

export default {
  name: 'CertificationCard',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    userCertifications: {
      type: Array,
      required: true
    },
    urlTitleIcon: {
      type: String
    }
  },
  components: { SelectYear, CertificationRow, ModalComponent, PrivateCard, SelectMonth },
  data () {
    return {
      createCertification: {
        centerName: null,
        name: null,
        observations: null,
        startAtYear: null,
        startAtMonth: null,
        finishedAtYear: null,
        finishedAtMonth: null,
        expirationYear: null,
        expirationMonth: null
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      if (this.editable) {
        this.$store.dispatch('getSectors')
        this.$store.dispatch('getRoles')
        this.$store.dispatch('getSkills')
        this.$store.dispatch('getDrivingLicenses')
      }
    },
    async onChangeStartMonth (month) {
      this.createCertification.startAtMonth = month
    },
    async onChangeStartYear (year) {
      this.createCertification.startAtYear = year
    },
    async onChangeFinishMonth (month) {
      this.createCertification.finishedAtMonth = month
    },
    async onChangeFinishYear (year) {
      this.createCertification.finishedAtYear = year
    },
    async onChangeExpirationMonth (month) {
      this.createCertification.expirationMonth = month
    },
    async onChangeExpirationYear (year) {
      this.createCertification.expirationYear = year
    },
    onClickEditLicensesAndCertifications () {
      this.$bvModal.show('create-licenses-certifications-modal')
    },
    onClickEditCertificationRow (certificationId) {
      this.$bvModal.show(certificationId)
    },
    async onConfirmedCreateCertifications (modalId) {
      const valid = await this.$refs.createCertificationsForm.validate()
      if (!valid) {
        return
      }

      this.lockScreen()
      this.$store.dispatch('createCertification', this.createCertification)
        .then(() => {
          this.$bvModal.hide(modalId)
          this.resetForm()
          this.$store.dispatch('showConfirmationModal')
        })
    },
    resetForm () {
      this.createCertification = {
        centerName: null,
        name: null,
        observations: null,
        startAtYear: null,
        startAtMonth: null,
        finishedAtYear: null,
        finishedAtMonth: null,
        expirationYear: null,
        expirationMonth: null
      }
    },
    removeCertification (certificationId) {
      this.lockScreen()
      this.$store.dispatch('deleteCertification', certificationId)
        .then(() => {
          this.$bvModal.hide('remove-confirmation' + certificationId)
          this.$bvModal.hide(certificationId)
          this.$store.dispatch('showConfirmationModal')
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
