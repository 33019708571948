<template>
  <validation-observer ref="updateHardwareForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-row>
        <b-col>
          <validation-provider name="nombre" rules="required" v-slot="validationContext">
            <b-form-group id="hardware-name" label-for="input-hardware-name">
              <h6>Nombre</h6>
              <b-form-input
                v-model="data.name"
                id="input-hardware-name"
                name="input-hardware-name"
                placeholder="Nombre del equipo"
                maxlength="50"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="marca" rules="required" v-slot="validationContext">
            <b-form-group id="hardware-brand" label-for="input-hardware-brand">
              <ProfessionalSkillSelect
                v-model="data.brandId"
                title="Marca"
                placeholder="Escribe la marca y elige de la lista"
                :options="hardwareBrandList"
                :suggestions="true"
                suggestionItem="marca"
                :selectedOptions="selectedBrand"
                eventName="removeRole"
                @onRemoveRole="onDeleteHardwareBrand"
                addEventName="addNew"
                @addNew="onAddHardwareBrand"
                :are-options-loaded="hardwareBrandList.length === 0"
                suggestionText="Sugiere una nueva"
                suggestionTitleText="No encuentras una"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="tipo de producto" rules="required" v-slot="validationContext">
            <b-form-group id="hardware-brand" label-for="input-hardware-brand">
              <ProfessionalSkillSelect
                v-model="data.productTypeId"
                title="Tipo de equipo"
                placeholder="Escribe el tipo de equipo y elige de la lista"
                :options="productTypeList"
                :suggestions="true"
                suggestionItem="tipo de equipo"
                :selectedOptions="selectedProductType"
                eventName="removeRole"
                @onRemoveRole="onDeleteHardwareProductType"
                addEventName="addNew"
                @addNew="onAddHardwareProductType"
                :are-options-loaded="productTypeList.length === 0"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="descripción" v-slot="validationContext">
            <b-form-group id="hardware-description" label-for="input-hardware-description">
              <h6>Descripción opcional</h6>
              <b-form-textarea
                v-model="data.description"
                id="input-hardware-description"
                name="input-hardware-description"
                maxlength="400"
                placeholder="Escribe una descripción del equipo"
                :state="getValidationState(validationContext)"
              ></b-form-textarea>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="hardware-description" label-for="input-hardware-description">
            <h6>
              Visibilidad
              <b-icon-question-circle-fill
                class="visible__icon"
                id="visible"
              />
            </h6>
            <div>
              <b-form-checkbox
                v-model="data.visible"
                class="avify-checkbox filter"
                name="deleteReason"
                :unchecked-value="false"
                :value="true"
              >
                Visible
              </b-form-checkbox>
            </div>
          </b-form-group>
          <b-popover target="visible" :show.sync="popover">
            <div class="h4" style="text-align: right">
              <b-icon-x style="color: black" @click="popover = !popover" />
            </div>
            <p>
              Si marcas Visible, tu equipamiento será visible en tu perfil público
            </p>
          </b-popover>
        </b-col>
      </b-row>
      <b-row class="footer">
        <b-col v-if="canDelete">
          <b-button variant="secondary" @click="onRemoveHardware" class="d-flex">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col class="footer-buttons">
          <b-button variant="primary" @click="onCreateHardware">
            Guardar cambios
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <AddSuggestionModal
      id="marcaModal"
      type="marca"
      suggestionText="Sugiere una nueva"
    />
  </validation-observer>
</template>

<script>
import { BIconTrash, BIconQuestionCircleFill, BIconX } from 'bootstrap-vue'
import AddSuggestionModal from '../Modal/AddSuggestionModal.vue'
import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect.vue'

export default {
  name: 'HardwareForm',
  components: { BIconTrash, AddSuggestionModal, ProfessionalSkillSelect, BIconQuestionCircleFill, BIconX },
  props: {
    value: Object,
    canDelete: Boolean
  },
  data () {
    const selectedBrand = []
    const selectedProductType = []
    return {
      hardwareBrandList: [],
      productTypeList: [],
      selectedBrand,
      selectedProductType,

      data: this.value,

      popover: false
    }
  },
  mounted: function () {
    this.$store.dispatch('getHardwareBrands')
      .then(res => {
        this.hardwareBrandList = res.map(hardwareBrand => {
          return { text: hardwareBrand.getName, value: hardwareBrand.getId }
        })
        if (this.value.brandId) {
          const brand = res.find(brand => {
            return brand.getId === this.value.brandId
          })
          this.selectedBrand.push(brand)
        }
      })

    this.$store.dispatch('getProductTypes')
      .then(res => {
        this.productTypeList = res.map(productType => {
          return { text: productType.getName, value: productType.getId }
        })
        if (this.value.productTypeId) {
          const productType = res.find(productType => {
            return productType.getId === this.value.productTypeId
          })
          this.selectedProductType.push(productType)
        }
      })
  },
  methods: {
    onAddHardwareBrand (brand) {
      this.selectedBrand = [brand]
      this.data.brandId = brand.id
    },
    onAddHardwareProductType (productType) {
      this.selectedProductType = [productType]
      this.data.productTypeId = productType.id
    },
    async onCreateHardware () {
      const valid = await this.$refs.updateHardwareForm.validate()
      if (valid) {
        this.$emit('confirmed', this.data)
      }
    },
    onRemoveHardware () {
      this.$emit('removed')
    },
    onDeleteHardwareBrand () {
      this.selectedBrand = []
      this.data.brandId = ''
    },
    onDeleteHardwareProductType () {
      this.selectedProductType = []
      this.data.productTypeId = ''
    }
  }
}
</script>

<style lang="less" scoped>
  @import 'hardwareForm.less';
</style>
