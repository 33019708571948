<template>
  <validation-observer ref="updateJobForm" v-slot="{ handleSubmit }">
    <b-form v-if="data" id="form-job" @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-row>
        <b-col>
          <validation-provider name="año" rules="required" v-slot="validationContext">
            <b-form-group id="job-year" label-for="input-job-year">
              <h6>Año</h6>
              <select-year
                id="input-job-year"
                name="input-job-year"
                v-model="data.year"
                :selectedYear="value.year"
                event-name="onChangeYear"
                :state="getValidationState(validationContext)"
                @onChangeYear="onChangeYear($event)"
                :maxYear="new Date().getFullYear()"
              ></select-year>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="trabajo" rules="required" v-slot="validationContext">
            <b-form-group id="word-name" label-for="input-job-name">
              <h6>Trabajo</h6>
              <b-form-input
                id="input-job-name"
                name="input-job-name"
                :state="getValidationState(validationContext)"
                placeholder="Escribe el trabajo que realizaste. E.g. LaGira Tour Alejandro Sanz"
                :value="value.name"
                @input="data.name = $event; $emit('input', data)"
                maxlength="50"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="descripción" rules="required" v-slot="validationContext">
            <b-form-group id="job-description" label-for="input-job-description">
              <h6>Descripción</h6>
              <b-form-textarea
                id="input-job-description"
                name="input-job-description"
                :state="getValidationState(validationContext)"
                placeholder="Escribe información sobre el trabajo que realizaste"
                :value="value.description"
                @input="data.description = $event; $emit('input', data)"
                maxlength="400"
              ></b-form-textarea>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="rol desempeñado" rules="required" v-slot="validationContext">
            <b-form-group id="job-role" label-for="input-job-role">
              <ProfessionalSkillSelect
                v-model="data.roleIds"
                title="Rol desempeñado"
                placeholder="Escribe y elige de la lista"
                :options="jobRoleList"
                suggestions
                suggestionItem="rol"
                :selectedOptions="selectedRole"
                eventName="removeRole"
                @onRemoveRole="onDeleteRole"
                addEventName="addNew"
                @addNew="onAddRole"
                :are-options-loaded="jobRoleList.length === 0"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="categoría" rules="required" v-slot="validationContext">
            <b-form-group id="job-category" label-for="input-job-category">
              <ProfessionalSkillSelect
                v-model="data.jobCategoryId"
                title="Categoría"
                placeholder="Selecciona una categoría"
                :options="categoryList"
                suggestionItem="category"
                :selectedOptions="selectedCategory"
                eventName="removeRole"
                @onRemoveRole="onDeleteCategory"
                addEventName="addNew"
                @addNew="onAddCategory"
                :are-options-loaded="categoryList.length === 0"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="enlace" v-slot="validationContext">
            <b-form-group id="job-link" label-for="input-job-link">
              <h6>Enlace opcional</h6>
              <b-form-input
                id="input-job-description"
                name="input-job-description"
                :state="getValidationState(validationContext)"
                placeholder="Comparte un enlace del evento"
                :value="value.link"
                @input="data.link = $event; $emit('input', data)"
                maxlength="400"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h6>Fotos</h6>
          <file-upload
            ref="upload"
            v-model="images"
            extensions="jpg,jpeg,gif,png,webp"
            accept="image/*"
            class="file-upload"
            multiple
            drop="#form-job"
            @input-file="inputFile"
          >
            <div class="image-collection" :class="{ filled: images.length > 0}">
              <div v-for="(image, index) in alreadyAddedImages" :key="image">
                <img :src="image" width="100">
                <span @click.stop="deleteAlreadyAddedImage(index)"><b-icon-trash variant="dark"></b-icon-trash></span>
              </div>
              <div v-for="(image, index) in images" :key="image.id">
                <img :src="image.thumb" width="100">
                <span @click.stop="deleteImage(index)"><b-icon-trash variant="dark"></b-icon-trash></span>
              </div>
            </div>
            <div class="message">
              <span>Haz clic o arrastra para añadir imágenes del trabajo realizado</span>
              <img :src="require('@/assets/icons/image.svg')">
            </div>
          </file-upload>
          <span class="cover-layer" v-show="uploader && uploader.dropActive">Arrastra las fotos aquí</span>
        </b-col>
      </b-row>
      <b-row class="footer">
        <b-col v-if="canDelete">
          <b-button variant="secondary" @click="onRemoveJob" class="d-flex">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col class="footer-buttons">
          <b-button variant="primary" @click="onCreateJob">
            Guardar cambios
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <AddSuggestionModal
      id="roleModal"
      type="role"
    />
  </validation-observer>
</template>

<script>
import { BIconTrash } from 'bootstrap-vue'
import AddSuggestionModal from '../Modal/AddSuggestionModal.vue'
import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect.vue'
import SelectYear from '../Form/SelectYear.vue'
import FileUpload from 'vue-upload-component'

export default {
  name: 'JobForm',
  components: { BIconTrash, AddSuggestionModal, ProfessionalSkillSelect, SelectYear, FileUpload },
  props: {
    value: Object,
    canDelete: Boolean
  },
  data () {
    return {
      jobRoleList: [],
      categoryList: [],
      selectedRole: [],
      selectedCategory: [],
      uploader: null,
      data: null,
      alreadyAddedImages: [],
      images: []
    }
  },
  mounted: function () {
    this.data = { ...this.value }
    this.data.images = [...this.value.images]
    this.data.roleIds = [...this.value.roleIds]
    this.data.deletedImages = []
    this.data.newImages = []

    this.jobRoleList = this.$store.getters.getRolesList
    this.$store.getters.getRolesList.map(role => {
      if (this.value.roleIds.indexOf(role.value) > -1) {
        this.selectedRole.push({
          id: role.value,
          name: role.text
        })
      }
    })

    this.$store.dispatch('getJobCategories')
      .then(res => {
        this.categoryList = res.map(jobCategory => {
          return { text: jobCategory.getName, value: jobCategory.getId }
        })
        if (this.value.jobCategoryId) {
          const category = res.find(jobCategory => {
            return jobCategory.getId === this.value.jobCategoryId
          })
          this.selectedCategory.push(category)
        }
      })

    this.$nextTick(function () {
      this.uploader = this.$refs.upload
    })

    this.alreadyAddedImages = this.data.images.map(image => {
      return process.env.VUE_APP_IMAGE_API_URL + image.path
    })
  },
  methods: {
    onAddRole (role) {
      if (this.selectedRole.filter(r => r.id === role.id).length === 0) {
        this.selectedRole.push(role)
        this.data.roleIds.push(role.id)
      }
    },
    onAddCategory (category) {
      this.selectedCategory = [category]
      this.data.jobCategoryId = category.id
    },
    async onCreateJob () {
      const valid = await this.$refs.updateJobForm.validate()
      if (valid) {
        this.$emit('confirmed', this.data)
      }
    },
    onRemoveJob () {
      this.$emit('removed')
    },
    onDeleteRole (role) {
      this.selectedRole = this.selectedRole.filter(r => r.id !== role)
      this.data.roleIds = this.data.roleIds.filter(r => r !== role)
    },
    onDeleteCategory () {
      this.selectedCategory = []
      this.data.jobCategoryId = ''
    },
    onChangeYear (year) {
      this.data.year = year
    },
    inputFile (newFile, oldFile) {
      newFile.thumb = URL.createObjectURL(newFile.file)
      this.data.newImages.push(newFile)
    },
    deleteAlreadyAddedImage (index) {
      this.alreadyAddedImages.splice(index, 1)
      const image = this.data.images.splice(index, 1)
      if (image.length === 1) {
        this.data.deletedImages.push({ id: image[0].id })
      }
    },
    deleteImage (index) {
      this.data.newImages.splice(index, 1)
      this.images.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
  @import 'jobForm.less';
</style>
