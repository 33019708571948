import EditableHardware from '../models/EditableHardware'

class EditableHardwareFactory {
  get (data) {
    const hardware = {
      id: data.id,
      name: data.name,
      description: data.description,
      brandId: data.brand.getId,
      productTypeId: data.productType.getId,
      visible: data.visible
    }
    return new EditableHardware(hardware)
  }
}

export default new EditableHardwareFactory()
