<template>
  <b-form-select
    :id="id"
    name="name"
    v-model="editableSelectedYear"
    placeholder="Año"
    :options="years"
    @change="onChangeSelectedYear"
    selected: null
    :state="state ? null : state"
  ></b-form-select>
</template>

<script>
export default {
  name: 'SelectYear',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    eventName: {
      type: String
    },
    selectedYear: {
      type: String
    },
    minYear: {
      type: Number,
      default: new Date().getFullYear() - 60
    },
    maxYear: {
      type: Number,
      default: new Date().getFullYear() + 60
    },
    state: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editableSelectedYear: this.selectedYear,
      years: []
    }
  },
  created () {
    for (let i = this.minYear; i <= this.maxYear; i++) {
      this.years.unshift({
        value: i.toString(),
        text: i.toString()
      })
    }

    this.years.unshift({
      value: null,
      text: 'Año'
    })
  },
  methods: {
    onChangeSelectedYear (selectedYear) {
      this.$emit(this.eventName, selectedYear)
    }
  }
}
</script>
