<template>
  <b-form-select
    id="input-certification-expiration-month"
    name="input-certification-expiration-month"
    v-model="editableSelectedMonth"
    placeholder="Mes"
    :options="months"
    @change="onChangeSelectedMonth"
    selected: null
  ></b-form-select>
</template>

<script>
export default {
  name: 'SelectMonth',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    eventName: {
      type: String
    },
    selectedMonth: {
      type: String
    }
  },
  data () {
    return {
      editableSelectedMonth: this.selectedMonth,
      months: [
        {
          value: null,
          text: 'Mes'
        },
        {
          value: '01',
          text: 'Enero'
        },
        {
          value: '02',
          text: 'Febrero'
        },
        {
          value: '03',
          text: 'Marzo'
        },
        {
          value: '04',
          text: 'Abril'
        },
        {
          value: '05',
          text: 'Mayo'
        },
        {
          value: '06',
          text: 'Junio'
        },
        {
          value: '07',
          text: 'Julio'
        },
        {
          value: '08',
          text: 'Agosto'
        },
        {
          value: '09',
          text: 'Septiembre'
        },
        {
          value: '10',
          text: 'Octubre'
        },
        {
          value: '11',
          text: 'Noviembre'
        },
        {
          value: '12',
          text: 'Diciembre'
        }
      ]
    }
  },
  methods: {
    onChangeSelectedMonth (selectedMonth) {
      this.$emit(this.eventName, selectedMonth)
    }
  }
}
</script>
