import EditableJob from '../models/EditableJob'

class EditableJobFactory {
  get (data, newImages, deletedImages) {
    if (typeof (newImages) === 'undefined') {
      newImages = []
    }
    if (typeof (deletedImages) === 'undefined') {
      deletedImages = []
    }
    const job = {
      id: data.id,
      name: data.name,
      description: data.description,
      year: data.year,
      roleIds: data.roles.map(e => e.id),
      jobCategoryId: data.jobCategory.id,
      link: data.link,
      images: data.images,
      newImages: newImages,
      deletedImages: deletedImages
    }
    return new EditableJob(job)
  }
}

export default new EditableJobFactory()
