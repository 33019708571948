export default class EditableJob {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.year = data.year
    this.roleIds = data.roleIds
    this.jobCategoryId = data.jobCategoryId
    this.link = data.link
    this.images = data.images
    this.newImages = data.newImages
    this.deletedImages = data.deletedImages
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getDescription () {
    return this.description
  }

  get getYear () {
    return this.year
  }

  get getRoleIds () {
    return this.roleIds
  }

  get getJobCategoryId () {
    return this.jobCategoryId
  }

  get getLink () {
    return this.link
  }

  get getNewImages () {
    return this.newImages
  }

  get getImages () {
    return this.images
  }

  get getDeletedImages () {
    return this.deletedImages
  }
}
