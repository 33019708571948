<template>
  <b-row  v-if="hardware.isVisible || editable" class="no-gutters d-block">
    <div class="d-flex justify-content-between align-items-start">
      <div class="hardware-row-text mr-3">
        <div class="hardware-row-title text-l">{{ hardware.getName }}</div>
        <div class="hardware-row-title">{{ hardware.getBrand.getName }} - {{ hardware.getProductType.getName }}</div>
        <div v-if="editable" class="hardware-row-title">
          <span v-if="hardware.isVisible">Visible</span>
          <span v-else>No Visible</span>
        </div>
        <div class="hardware-row-desc" v-if="hardware.getDescription">
          {{ hardware.getDescription }}
        </div>
      </div>
      <div class="hardware-row-icon" v-if="editable">
        <img
          src="@/components/CertificationRow/edit_card.svg"
          style="cursor: pointer"
          @click="$bvModal.show(hardware.getId)"
        >
      </div>
    </div>
    <hr v-if="separator">
    <modal-component
      :id="hardware.getId"
      :deleteButton="true"
      title="Editar equipación"
      size="lg"
      ok-title="Guardar cambios"
      hide-footer
      hide-top
    >
      <HardwareForm
        v-model="editableHardware"
        canDelete
        @confirmed="onConfirmedUpdateHardware"
        @removed="onDeleteHardware"
      />
    </modal-component>
    <remove-confirmation-modal
      :id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmedRemoveHardware"
      @onCancelRemoveConfirmation="onCancelRemoveHardware"
    ></remove-confirmation-modal>
  </b-row>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import EditableHardwareFactory from '../../domain/services/EditableHardwareFactory'
import RemoveConfirmationModal from '../Modal/RemoveConfirmationModal'
import HardwareForm from './HardwareForm.vue'

export default {
  name: 'HardwareRow',
  components: {
    RemoveConfirmationModal,
    ModalComponent,
    HardwareForm
  },
  props: {
    hardware: {
      type: Object,
      required: true
    },
    separator: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const editableHardware = Object.assign({}, this.hardware)
    return {
      editableHardware: EditableHardwareFactory.get(editableHardware)
    }
  },
  computed: {
    confirmationModalId () {
      return 'remove-confirmation' + this.editableHardware.id
    }
  },
  methods: {
    async onConfirmedUpdateHardware (editableHardware) {
      this.$emit('updateHardware', editableHardware)
    },
    onDeleteHardware () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onConfirmedRemoveHardware () {
      this.$bvModal.hide(this.hardware.getId)
      this.onCancelRemoveHardware()
      this.$emit('removeHardware', this.hardware.getId)
    },
    onCancelRemoveHardware () {
      this.$bvModal.hide(this.confirmationModalId)
    }
  }
}
</script>
