<template>
  <private-card
    title="Sobre mí"
    :urlTitleIcon="urlTitleIcon"
    eventName="onClickEditAboutMe"
    :editIcon="editable"
    @onClickEditAboutMe="onClickEditAboutMe"
  >
    <template v-slot:private-card-body>
      <nl2br
        tag="div"
        :text="extendedUser.getDescription"
        v-if="extendedUser.getDescription != null" />
    </template>

    <template v-slot:private-card-modal>
      <modal-component
        id="edit-about-me-modal"
        title="Editar sobre mí"
        size="lg"
        ok-title="Guardar cambios"
        @confirmed="onConfirmedEditAboutMe"
      >
        <validation-observer ref="editAboutMeForm" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
            <b-row>
              <b-col>
                <validation-provider vid="about-me" name="descripción" rules="required|max:2500" v-slot="validationContext">
                  <b-form-group id="about-me" label-for="input-username">
                    <b-form-textarea
                      id="about-me"
                      name="input-about-me"
                      v-model="descriptionData.description"
                      :state="getValidationState(validationContext)"
                      placeholder="Aquí puede incluir una pequeña descripción sobre mi"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                  <p style="white-space: pre-line" class="text-sm">
                    <span v-if="descriptionData.description !== null">{{ descriptionData.description.length }} de 2500 caracteres</span>
                    <span v-else>0 de 2500 caracteres</span>
                  </p>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </modal-component>
    </template>
  </private-card>
</template>

<script>
import PrivateCard from '@/components/Card/PrivateCard'
import ModalComponent from '@/components/Modal/ModalComponent'

export default {
  name: 'AboutMe',
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    extendedUser: {
      type: Object,
      required: true
    },
    urlTitleIcon: {
      type: String
    }
  },
  components: {
    PrivateCard,
    ModalComponent
  },
  data () {
    return {
      descriptionData: {
        description: null
      }
    }
  },
  mounted: function () {
    this.descriptionData.description = this.extendedUser.getDescription
  },
  methods: {
    onClickEditAboutMe () {
      this.$bvModal.show('edit-about-me-modal')
    },
    async onConfirmedEditAboutMe (modalId) {
      const valid = await this.$refs.editAboutMeForm.validate()
      if (!valid) {
        return
      }
      this.lockScreen()
      this.$store.dispatch('updateUserDescription', this.descriptionData)
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide(modalId)
            this.$store.dispatch('showConfirmationModal')
          })
        })
    }
  }
}
</script>
