export default class EditableHardware {
  constructor (data) {
    this.id = data.id
    this.brandId = data.brandId
    this.productTypeId = data.productTypeId
    this.name = data.name
    this.description = data.description
    this.visible = data.visible
  }

  get getId () {
    return this.id
  }

  get getBrandId () {
    return this.brandId
  }

  get getProductTypeId () {
    return this.productTypeId
  }

  get getName () {
    return this.name
  }

  get getDescription () {
    return this.description
  }

  get isVisible () {
    return this.visible
  }
}
