<template>
  <b-row class="no-gutters d-block">
    <div class="d-flex justify-content-between align-items-start">
      <div class="job-row-text mr-3 flex-grow-1" :class="editable ? 'editable' : 'non-editable'">
        <div class="job-row-title text-l">{{ job.getName }}</div>
        <div class="job-row-title">{{ job.getYear }} - {{ job.getJobCategory.name }}</div>
        <div class="job-row-title"><strong>Rol desempeñado:</strong> {{ roleNames }}</div>
        <div class="job-row-description mt-4">
          {{ job.getDescription }}
        </div>
        <div class="job-row-link mt-4" v-if="job.getLink">
          <a :href="parsedLink" target="_blank">{{ job.getLink }}</a>
        </div>
        <div
          class="job-row-images mt-4"
            v-if="job.getImages.length > 0"
        >
          <VueAgile
            :options="galleryOptions"
          >
            <template slot="prevButton">
              <div class="icon-container">
                <BIconChevronLeft class="icons" />
              </div>
            </template>
            <template slot="nextButton">
              <div class="icon-container">
                <BIconChevronRight class="icons" />
              </div>
            </template>
            <div v-for="(image, i) in job.getImages" :key="image.id" @click="index = i">
              <img :src="baseUrl + image.path" width="120">
            </div>
          </VueAgile>
          <VueGallerySlideshow
            :images="images"
            :index="index"
          ></VueGallerySlideshow>
        </div>
      </div>
      <div class="job-row-icon" v-if="editable">
        <img
          src="@/components/CertificationRow/edit_card.svg"
          style="cursor: pointer"
          @click="$bvModal.show(job.getId)"
        >
      </div>
    </div>
    <hr v-if="separator">
    <modal-component
      :id="job.getId"
      :deleteButton="true"
      title="Editar equipación"
      size="lg"
      ok-title="Guardar cambios"
      hide-footer
      hide-top
    >
      <JobForm
        :value="editableJob"
        canDelete
        @confirmed="onConfirmedUpdateJob"
        @removed="onDeleteJob"
      />
    </modal-component>
    <remove-confirmation-modal
      :id="confirmationModalId"
      @onConfirmRemoveConfirmation="onConfirmedRemoveJob"
      @onCancelRemoveConfirmation="onCancelRemoveJob"
    ></remove-confirmation-modal>
  </b-row>
</template>

<script>
import ModalComponent from '@/components/Modal/ModalComponent'
import EditableJobFactory from '../../domain/services/EditableJobFactory'
import RemoveConfirmationModal from '../Modal/RemoveConfirmationModal'
import JobForm from './JobForm.vue'
import { VueAgile } from 'vue-agile'
import { BIconChevronLeft, BIconChevronRight } from 'bootstrap-vue'
import VueGallerySlideshow from 'vue-gallery-slideshow'

export default {
  name: 'JobRow',
  components: {
    RemoveConfirmationModal,
    ModalComponent,
    JobForm,
    VueAgile,
    BIconChevronLeft,
    BIconChevronRight,
    VueGallerySlideshow
  },
  props: {
    job: {
      type: Object,
      required: true
    },
    separator: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL,
      index: null,
      galleryOptions: {
        infinite: true,
        dots: false,
        centerMode: false,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            navButtons: this.job.getImages.length > 1
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: this.job.getImages.length > 5 ? 5 : this.job.getImages.length,
            centerMode: true,
            navButtons: this.job.getImages.length > 5
          }
        }]
      }
    }
  },
  computed: {
    parsedLink () {
      if (this.job.getLink.indexOf('http') !== 0) {
        return 'https://' + this.job.getLink
      }

      return this.job.getLink
    },
    editableJob () {
      return { ...EditableJobFactory.get(this.job) }
    },
    confirmationModalId () {
      return 'remove-confirmation' + this.job.getId
    },
    roleNames () {
      return this.job.getRoles.map(r => r.name).join(', ')
    },
    images () {
      return this.job.getImages.map(i => this.baseUrl + i.path)
    }
  },
  methods: {
    async onConfirmedUpdateJob (job) {
      this.$emit('updateJob', job)
    },
    onDeleteJob () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onConfirmedRemoveJob () {
      this.$bvModal.hide(this.job.getId)
      this.onCancelRemoveJob()
      this.$emit('removeJob', this.job.getId)
    },
    onCancelRemoveJob () {
      this.$bvModal.hide(this.confirmationModalId)
    }
  }
}
</script>

<style lang="less">
  .editable {
    width: 90%;
  }
  .non-editable {
    width: 100%;
  }
  .job-row-images {
    .agile {
      &__nav-button {
        position: absolute;
        top: 0;
        height: 100%;
        background-color: transparent;
        border: none;
        &--prev {
          left: 0;
        }
        &--next {
          right: 0;
        }
        .icon-container {
          background-color: #00000055;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          .icons {
            color: white;
          }
        }
      }
    }
  }
</style>
