<template>
  <private-card
    title="Perfil profesional"
    :urlTitleIcon="urlTitleIcon"
    :editIcon="editable"
    eventName="onClickEditProfesionalProfile"
    @onClickEditProfesionalProfile="onClickEditProfesionalProfile"
  >
    <template v-slot:private-card-body>
      <div v-if="editable === true">
        <div>
          <div>
            <div class="text-l">Autónomo</div>
            <div class="mt-1">{{ freelancerText }}</div>
          </div>
          <hr>
        </div>
        <div>
          <div>
            <div class="text-l">Disponible</div>
            <div class="mt-1">{{ availableText }}</div>
          </div>
          <hr>
        </div>
      </div>
      <ProfessionalSkillRow title="Sectores" :separator="true">
        <template v-slot:professional-skill-list>
          <SkillBadge
            :name="sector.name"
            :key="sector.id"
            v-for="sector in extendedUser.getSectors" />
        </template>
      </ProfessionalSkillRow>
      <ProfessionalSkillRow title="Roles" :separator="true">
        <template v-slot:professional-skill-list>
          <SkillBadge
            :name="role.name"
            :key="role.id"
            v-for="role in extendedUser.getRoles" />
        </template>
      </ProfessionalSkillRow>
      <ProfessionalSkillRow title="Habilidades" :separator="true">
        <template v-slot:professional-skill-list>
          <SkillBadge
            :name="skill.name"
            :key="skill.id"
            v-for="skill in extendedUser.getSkills" />
        </template>
      </ProfessionalSkillRow>
      <ProfessionalSkillRow title="Licencias de conducir" :separator="false">
        <template v-slot:professional-skill-list>
          <SkillBadge
            :name="drivingLicense.name"
            :key="drivingLicense.id"
            v-for="drivingLicense in extendedUser.getDrivingLicenses" />
        </template>
      </ProfessionalSkillRow>
    </template>
    <template v-slot:private-card-modal>
      <modal-component
        id="edit-skills-modal"
        title="Editar perfil profesional"
        size="lg"
        ok-title="Guardar cambios"
        @confirmed="onConfirmedSkills"
      >
        <b-row class="checkboxes">
          <b-col>
            <b-form-checkbox
              class="avify-checkbox filter"
              v-model="user.technician.freelancer"
            >
              <h6>Autónomo</h6>
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              class="avify-checkbox filter"
              v-model="user.technician.available"
            >
              <h6>Disponible</h6><img class="icon info-icon" :src="require('@/assets/icons/info.svg')" v-b-tooltip.hover :title="availableTooltipText">
            </b-form-checkbox>
          </b-col>
        </b-row>

        <ProfessionalSkillSelect
          title="Sectores"
          placeholder="Selecciona uno o varios sectores"
          :options="sectorOptions"
          :suggestions="true"
          suggestionItem="sector"
          :selectedOptions="userSectorsList"
          eventName="removeSector"
          @onRemoveSector="onRemoveSectorSkill"
          addEventName="addNewSector"
          @addNewSector="onAddSector"
          :are-options-loaded="sectorOptions.length === 0"
        />

        <ProfessionalSkillSelect
          title="Roles"
          placeholder="Selecciona uno o varios roles"
          :options="roleOptions"
          type="rol"
          :suggestions="true"
          suggestionItem="rol"
          :selectedOptions="userRolesList"
          eventName="removeRole"
          @onRemoveRole="onRemoveRoleSkill"
          addEventName="addNewRole"
          @addNewRole="onAddRole"
          :are-options-loaded="roleOptions.length === 0"
        />

        <ProfessionalSkillSelect
          title="Habilidades"
          placeholder="Selecciona una o varias habilidades"
          :options="skillsOptions"
          type="habilidad"
          :suggestions="true"
          suggestionItem="habilidad"
          :selectedOptions="userSkillsList"
          eventName="removeSkill"
          @onRemoveSkill="onRemoveSkill"
          addEventName="addNewSkill"
          @addNewSkill="onAddSkill"
          :are-options-loaded="skillsOptions.length === 0"
        />
        <ProfessionalSkillSelect
          title="Licencias de conducir"
          placeholder="Selecciona una o varias licencias de conducir"
          :options="drivingLicensesOptions"
          type="drivingLicense"
          :selectedOptions="userDrivingLicensesList"
          eventName="removeDrivingLicense"
          @onRemoveDrivingLicense="onRemoveDrivingLicense"
          addEventName="addNewDrivingLicense"
          @addNewDrivingLicense="onAddDrivingLicense"
          :are-options-loaded="drivingLicensesOptions.length === 0"
        />
      </modal-component>
    </template>
  </private-card>
</template>

<script>
/* eslint vue/no-mutating-props: 0 */

import PrivateCard from '../Card/PrivateCard'
import ProfessionalSkillRow from '../ProfessionalSkillRow/ProfessionalSkillRow'
import ProfessionalSkillSelect from '../ProfessionalSkillSelect/ProfessionalSkillSelect'
import SkillBadge from '../SkillBadge/SkillBadge'
import ModalComponent from '../Modal/ModalComponent'
export default {
  name: 'ProfessionalProfileCard',
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    },
    extendedUser: {
      type: Object,
      required: true
    },
    urlTitleIcon: {
      type: String
    }
  },
  data () {
    return {
      userSectorsList: [],
      userRolesList: [],
      userSkillsList: [],
      userDrivingLicensesList: [],
      availableTooltipText: 'Recibe en tu email ofertas de trabajo que se ajusten a tu perfil'
    }
  },
  components: { ModalComponent, SkillBadge, ProfessionalSkillRow, PrivateCard, ProfessionalSkillSelect },
  created: function () {
    this.userSectorsList = [...this.extendedUser.getSectors]
    this.userRolesList = [...this.extendedUser.getRoles]
    this.userSkillsList = [...this.extendedUser.getSkills]
    this.userDrivingLicensesList = [...this.extendedUser.getDrivingLicenses]
  },
  computed: {
    sectorOptions () {
      return this.$store.getters.getSectorsList
    },
    roleOptions () {
      return this.$store.getters.getRolesList
    },
    skillsOptions () {
      return this.$store.getters.getSkillsList
    },
    drivingLicensesOptions () {
      return this.$store.getters.getDrivingLicensesList
    },
    freelancerText () {
      return this.user.technician.freelancer ? 'Si' : 'No'
    },
    availableText () {
      return this.user.technician.available ? 'Si' : 'No'
    }
  },
  methods: {
    onClickEditProfesionalProfile () {
      this.$bvModal.show('edit-skills-modal')
    },
    onConfirmedSkills () {
      this.lockScreen()
      const promises = [
        this.replaceUserSectors(),
        this.replaceUserRoles(),
        this.replaceUserSkills(),
        this.replaceUserDrivingLicenses(),
        this.updateFreelancer(),
        this.updateAvailable()
      ]

      Promise.all(promises).then(() => {
        this.$bvModal.hide('edit-skills-modal')
        this.unlockScreen()
        this.$store.dispatch('showConfirmationModal')
      })
    },
    replaceUserSectors () {
      return new Promise((resolve) => {
        this.$store.dispatch('replaceUserSectors', this.userSectorsList)
          .then(() => resolve())
      })
    },
    replaceUserRoles () {
      return new Promise((resolve) => {
        this.$store.dispatch('replaceUserRoles', this.userRolesList)
          .then(() => resolve())
      })
    },
    replaceUserSkills () {
      return new Promise((resolve) => {
        this.$store.dispatch('replaceUserSkills', this.userSkillsList)
          .then(() => resolve())
      })
    },
    replaceUserDrivingLicenses () {
      return new Promise((resolve) => {
        this.$store.dispatch('replaceUserDrivingLicenses', this.userDrivingLicensesList)
          .then(() => resolve())
      })
    },
    updateFreelancer () {
      return new Promise((resolve) => {
        this.$store.dispatch('updateUserTechnicianFreelancer', this.user.technician.freelancer)
          .then(() => resolve())
      })
    },
    updateAvailable () {
      return new Promise((resolve) => {
        this.$store.dispatch('updateUserTechnicianAvailable', this.user.technician.available)
          .then(() => resolve())
      })
    },
    onRemoveSectorSkill (id) {
      this.userSectorsList.forEach((sector, index) => {
        if (sector.id === id) {
          this.userSectorsList.splice(index, 1)
        }
      })
    },
    onAddSector (sector) {
      const exists = this.userSectorsList.findIndex(
        item => item.id === sector.id
      )
      if (exists === -1) {
        this.userSectorsList.push(sector)
      }
    },
    onRemoveRoleSkill (id) {
      this.userRolesList.forEach((role, index) => {
        if (role.id === id) {
          this.userRolesList.splice(index, 1)
        }
      })
    },
    onAddRole (role) {
      const exists = this.userRolesList.findIndex(
        item => item.id === role.id
      )
      if (exists === -1) {
        this.userRolesList.push(role)
      }
    },
    onRemoveSkill (id) {
      this.userSkillsList.forEach((skill, index) => {
        if (skill.id === id) {
          this.userSkillsList.splice(index, 1)
        }
      })
    },
    onAddSkill (skill) {
      const exists = this.userSkillsList.findIndex(
        item => item.id === skill.id
      )
      if (exists === -1) {
        this.userSkillsList.push(skill)
      }
    },
    onRemoveDrivingLicense (id) {
      this.userDrivingLicensesList.forEach((drivingLicense, index) => {
        if (drivingLicense.id === id) {
          this.userDrivingLicensesList.splice(index, 1)
        }
      })
    },
    onAddDrivingLicense (drivingLicense) {
      const exists = this.userDrivingLicensesList.findIndex(
        item => item.id === drivingLicense.id
      )
      if (exists === -1) {
        this.userDrivingLicensesList.push(drivingLicense)
      }
    }
  }
}
</script>

<style lang="less">
  @import '../../style/colors.less';
  @import "../../style/text";

  .pink {
    color: @color_radiant_pink;
  }
  .row.checkboxes {
    margin-bottom: 1rem;
  }
  .row {
    h6 {
      font-family: @font_fira;
      display: inline-block;
      margin-right: 10px;
    }
    .avify-checkbox {
      display: inline-block;
    }
  }

  .info-icon {
    margin-bottom: 0.5rem;
  }
</style>
